import { GlobalActionType, GlobalState, SET_ERROR_MESSAGE, SET_NEW_STANDARD_MESSAGE, SET_SUCCESS_MESSAGE, SET_USERNAME } from "./types";

export const initialState: GlobalState = { 
    errorMessage: "",
    successMessage: "",
    userName: "",
    newStandardMessage: ""
}

export function globalReducer(
    state = initialState,
    action: GlobalActionType
): GlobalState {
    switch (action.type) {
        case SET_ERROR_MESSAGE:
            return {
                ...state,
                errorMessage: action.payload,
            }
        case SET_SUCCESS_MESSAGE:
            return {
                ...state,
                successMessage: action.payload,
            }
        case SET_USERNAME:
            return {
                ...state,
                userName: action.payload,
            }
        case SET_NEW_STANDARD_MESSAGE:
            return {
                ...state,
                newStandardMessage: action.payload,
            }
        default:
            return state;
    }
}