
import { EmergencyMessageModel } from "../../components/emergencyMessage/models/emergencyMessgeModel";
import { EmergencyMessageActionType, SET_COUNTRY, SET_EMERGENCY_MESSAGE, SET_SHOW_ADDITIONAL_COMPONENT } from "./types";

export function setEmergencyMessageGlobal(emergencyMessage: EmergencyMessageModel): EmergencyMessageActionType {
    return {
        type: SET_EMERGENCY_MESSAGE,
        payload: emergencyMessage,
    }
}

export function setCountry(country: string) {
    return {
        type: SET_COUNTRY,
        payload: country,
    }
}

export function setShowAdditionalComponent(showAdditionalComponent: boolean) {
    return {
        type: SET_SHOW_ADDITIONAL_COMPONENT,
        payload: showAdditionalComponent,
    }
}