var baseUrl = process.env.REACT_APP_BASE_API_URL
var apiKey = process.env.REACT_APP_API_KEY;

export const GetOutbases = (country: string) => {
    return fetch(baseUrl + 'outbases?country=' + country, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': apiKey!
        }
    });
}

export const GetLoadsForOutbase = (country: string, outbase: string) => {
    return fetch(baseUrl + 'outbaseLoads?outbase=' + outbase + '&country=' + country, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': apiKey!
        }
    });
}