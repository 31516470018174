import GetOutbaseMessage from '../../components/outbase/getOutbaseMessage';
import UpdateOutbaseMessage from '../../components/outbase/updateOutbaseMessage';
import ErrorMessage from '../../components/global/errorMessage';
import { useTranslation } from 'react-i18next';

export function UpdateOutbase() {

    const { t } = useTranslation();

    return (
        <div>
            <div className="row m-2 mt-3">
                <h3 className="col-md-11 offset-md-1">{t("outbaseMessage:addUpdateOutbase")}</h3>
                <p className="col-md-11 offset-md-1">{t("outbaseMessage:getOutbasesTitle")}</p>
            </div>
            <div>
                <GetOutbaseMessage></GetOutbaseMessage>
                <UpdateOutbaseMessage></UpdateOutbaseMessage>
                <ErrorMessage></ErrorMessage>
            </div>
        </div>
    )
}