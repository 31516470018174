import { EmergencyMessageActionType, EmergencyMessageState, SET_COUNTRY, SET_EMERGENCY_MESSAGE, SET_SHOW_ADDITIONAL_COMPONENT } from "./types";

export const initialState: EmergencyMessageState = {
  emergencyMessage: {
    language: "",
    title: "",
    emergencyMessage: "",
    link: "",
    linkText: ""
  },
  country: "ENG",
  showAdditionalComponent: false,
}

export function emergencyMessageReducer(
  state = initialState,
  action: EmergencyMessageActionType
): EmergencyMessageState {
  switch (action.type) {
    case SET_EMERGENCY_MESSAGE:
      return {
        ...state,
        emergencyMessage: action.payload,
      }
      case SET_COUNTRY:
        return {
          ...state,
          country: action.payload,
        }
      case SET_SHOW_ADDITIONAL_COMPONENT:
        return {
          ...state,
          showAdditionalComponent: action.payload,
        }
    default:
      return state;
  }
}

