import './updatestandardmessage.css';
import { postStandardMessage } from '../../services/standardMessageService';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from '../../store';
import { setMessage, setShowAdditionalComponent } from '../../store/standardMessage/actions';
import { setErrorMessage, setSuccessMessage } from '../../store/global/actions';
import { StandardMessageList } from '../global/standardMessageList';

export function UpdateStandardMessage() {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const showAdditionalComponent = useSelector(
        (state: GlobalState) => state.standardMessage.showAdditionalComponent
    );

    const loadNumber = useSelector(
        (state: GlobalState) => state.standardMessage.loadNumber
    );

    const userName = useSelector(
        (state: GlobalState) => state.global.userName
    );

    const messageSelected = useSelector(
        (state: GlobalState) => state.global.newStandardMessage
    );

    async function addUpdateMessage() {
        if (messageSelected === "") {
            dispatch(setErrorMessage(t('standardLoadMessage:mustSelectAMessage')));
        } else {
            dispatch(setErrorMessage(""));

            setTimeout(() => {
                if (!response) {
                    dispatch(setShowAdditionalComponent(false));
                    setErrorMessage(t('standardLoadMessage:cannotConnectToService'));
                }
            }, 10000);

            var response = await postStandardMessage(loadNumber, messageSelected, '', userName);            

            if (response.status === 200) {
                dispatch(setShowAdditionalComponent(false));
                dispatch(setMessage(messageSelected));
                dispatch(setSuccessMessage(t('standardLoadMessage:successUpdateStandardMessage')));
                setTimeout(() => {
                    dispatch(setSuccessMessage(t('')));
                }, 5000);
            } else {
                dispatch(setErrorMessage(t('standardLoadMessage:cannotConnectToService')));
                dispatch(setShowAdditionalComponent(false));
            }
        }
    }

    return (
        <div id='addUpdateStandardMessageContainer'>
            {showAdditionalComponent && <div id='showUpdateStandardMessage'>
                <div className='row'>
                    <hr className="col-md-10 offset-md-1"></hr>
                    <span className='col-md-10 offset-md-1'><b>{t('standardLoadMessage:enterLoadNumber')}</b></span>
                    <label className='col-md-3 offset-md-1 mt-2'><b>{t('standardLoadMessage:loadNumber')}</b></label>
                    <span className='col-md-6 mt-2'>{loadNumber}</span>
                </div>
                <StandardMessageList></StandardMessageList>
                <div className='row' id='submitUpdateMessage'>
                    <span className='col-md-6 offset-md-1'><b>{t('standardLoadMessage:updateMessage')}</b></span>
                    <button onClick={addUpdateMessage} className='col-md-1 offset-md-2 btn btn-primary'>{t('standardLoadMessage:yes')}</button>
                </div>
            </div>}
        </div>
    )
}

export default UpdateStandardMessage