import { GlobalActionType, SET_ERROR_MESSAGE, SET_NEW_STANDARD_MESSAGE, SET_SUCCESS_MESSAGE, SET_USERNAME } from "./types";

export function setErrorMessage(errorMessage: string) : GlobalActionType {
    return {
        type: SET_ERROR_MESSAGE,
        payload: errorMessage,
    }
}

export function setSuccessMessage(successMessage: string) : GlobalActionType {
    return {
        type: SET_SUCCESS_MESSAGE,
        payload: successMessage,
    }
}

export function setUserName(userName: string) : GlobalActionType {
    return {
        type: SET_USERNAME,
        payload: userName
    }
}

export function setNewStandardMessage(newStandardMessage: string) : GlobalActionType {
    return {
        type: SET_NEW_STANDARD_MESSAGE,
        payload: newStandardMessage
    }
}