import { useTranslation } from 'react-i18next';
import './getstandardmessages.css';
import { getStandardMessages } from '../../services/standardMessagesService';
import { useDispatch, useSelector } from 'react-redux';
import { setErrorMessage } from '../../store/global/actions';
import { setLoadResponse, setLoads, setShowAdditionalComponent } from '../../store/standardMessages/actions';
import { GlobalState } from '../../store';
import { useEffect, useState } from 'react';
import { StandardMessageModel } from './models/standardMessageModel';

export function GetStandardMessages() {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const loads = useSelector(
        (state: GlobalState) => state.standardMessages.loads
    );

    const loadsResponse = useSelector(
        (state: GlobalState) => state.standardMessages.loadResponse
    );
    const [validLoadsCheck, setValidLoadsCheck] = useState(false);
    
    const validLoads = RegExp(/^[a-zA-Z0-9, ]{4,200}$/);

    useEffect(() => {
        dispatch(setErrorMessage(""));
        dispatch(setShowAdditionalComponent(false));
        dispatch(setLoads(""));
        dispatch(setLoadResponse([]));
    }, [dispatch]);

    async function getLoads() {
        dispatch(setErrorMessage(""));
        dispatch(setLoadResponse([]));

        if (!validLoadsCheck) {
            dispatch(setErrorMessage(t('standardLoadsMessage:enterLoadNumbersError')));
        } else {

            setTimeout(() => {
                if (!response) {
                    dispatch(setErrorMessage(t('standardLoadMessage:cannotConnectToService')));
                }
            }, 10000);

            var response = await getStandardMessages(loads);

            const resJson = (await response.json()) as StandardMessageModel[];
            dispatch(setShowAdditionalComponent(true));
            if (resJson.length === 0) {
                dispatch(setErrorMessage(t('standardLoadsMessage:noStandardMessages')));
            } else {
                dispatch(setLoadResponse(resJson));
            }
        }
    }

    function updateLoads(e: any) {
        dispatch(setLoads(e.target.value));
        const loads = validLoads.test(e.target.value);
        if(!loads) {
            setValidLoadsCheck(false);
        } else {
            setValidLoadsCheck(true);
        }
    }

    return (
        <div id="getStandardMessagesContainer">
            <div className="row">
                <div className="col-md-10 offset-md-1">
                    <textarea className="textArea" onChange={updateLoads} placeholder={t("app:between5and200")}></textarea>
                </div>
                <div className="col-md-1 mt-3">
                        {validLoadsCheck && <i className="valid bi bi-check-lg"></i>}
                        {!validLoadsCheck && <i className="notValid bi bi-x-lg"></i>}
                    </div>
                <div className="col-md-2 offset-md-1">
                    <button className="btn btn-success button" onClick={getLoads}>{t('standardLoadsMessage:getLoads')}</button>
                </div>
            </div>
            <div className="mt-4 ml-1">
                {loadsResponse.map(load =>
                    <div className="row" key={load.loadNumber}>
                        <span className="col-md-3 offset-md-1">{load.loadNumber}</span>
                        <span className="col-md-7">{load.message}</span>
                        <hr className="col-md-10 offset-md-1"></hr>
                    </div>
                )}
            </div>
        </div>
    )
}

export default GetStandardMessages