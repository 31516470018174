import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from '../../store';
import { setErrorMessage, setSuccessMessage } from '../../store/global/actions';
import { setShowAdditionalComponent } from '../../store/standardMessage/actions';
import './deletestandardmessage.css';
import { deleteStandardMessage } from '../../services/standardMessageService';

export function DeleteStandardMessage() {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const showAdditionalComponent = useSelector(
        (state: GlobalState) => state.standardMessage.showAdditionalComponent
    );
    const loadNumber = useSelector(
        (state: GlobalState) => state.standardMessage.loadNumber
    );
    const errorMessage = useSelector(
        (state: GlobalState) => state.global.errorMessage
    );
    
    const userName = useSelector(
        (state: GlobalState) => state.global.userName
    );

    async function deleteMessage() {
        dispatch(setErrorMessage(""));
        setTimeout(() => {
            if (!response) {
                dispatch(setErrorMessage(t('standardLoadMessage:cannotConnectToService')));
            }
        }, 10000);

        var response = await deleteStandardMessage(loadNumber, userName);

        if (response.status === 200) {
            dispatch(setSuccessMessage(t('standardLoadMessage:deletedSuccessfully')));
            dispatch(setShowAdditionalComponent(false));
            setTimeout(() => {
                dispatch(setSuccessMessage(t('')));
            }, 5000);
        } else {
            dispatch(setErrorMessage(t('standardLoadMessage:cannotConnectToService')));
        }
    }

    return (
        <div id='deleteStandardMessageContainer'>
            {showAdditionalComponent && errorMessage === "" &&
                <div id='showGetLoadMessage'>
                    <div className='row' id='submitDeleteStandardMessage'>
                        <hr className="col-md-10 offset-md-1"></hr>
                        <span className='col-md-7 offset-md-1'><b>{t('standardLoadMessage:deleteThisMessage')}</b></span>
                        <button onClick={deleteMessage} className='col-md-1 offset-md-1 btn btn-primary'>{t('standardLoadMessage:yes')}</button>
                    </div>
                </div>}
        </div>
    )
}

export default DeleteStandardMessage