import UpdateStandardMessages from '../../components/standardMessages/updatestandardmessages';
import ErrorMessage from "../../components/global/errorMessage";
import SuccessMessage from "../../components/global/successMessage";
import GetStandardMessages from '../../components/standardMessages/getstandardmessages';
import { useTranslation } from 'react-i18next';

export function UpdateStandards() {

    const { t } = useTranslation();

    return (
        <div>
            <div className="row m-2 mt-3">

                <h3 className='col-md-11 offset-md-1'>{t('standardLoadsMessage:addUpdateMessages')}</h3>
                <p className="offset-md-1 col-md-10">{t('standardLoadsMessage:enterLoadNumbers')}</p>
            </div>
            <div>
                <GetStandardMessages></GetStandardMessages>
                <UpdateStandardMessages></UpdateStandardMessages>
                <ErrorMessage></ErrorMessage>
                <SuccessMessage></SuccessMessage>
            </div>
        </div>
    )
}