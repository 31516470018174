import './Header.css';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import ukFlag from './Assets/uk.png';
import germanFlag from './Assets/german.png';

export function Header() {

    const { t } = useTranslation();

    function changeToEnglish() {
        i18next.changeLanguage('en');
    }

    function changeToGerman() {
        i18next.changeLanguage('de');
    }

    return (
        <div id="header" className="row">
            <a href='/' className="col-md-2">
                <img id='logo' alt='ao-logo' src={('https://media.ao.com/trackyourorder/vi-update/ao-logo-transparent.png')} />
            </a>
            <h2 className="col-md-8 col-11" id='title'>{t('app:trackYourOrderSelfServeMessaging')}</h2>
            <div className="col-md-2 col-1 flags">
                <img onClick={changeToEnglish} alt="" className="flag" src={ukFlag}></img>
                <img onClick={changeToGerman} alt="" className="flag" src={germanFlag}></img>
            </div>

        </div>
    );
};
