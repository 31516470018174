import { Action, applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import ReduxThunk, { ThunkAction } from 'redux-thunk';
import { emergencyMessageReducer } from './emergencyMessage/reducers';
import { outbaseReducer } from './outbase/reducers';
import { globalReducer } from './global/reducers';
import { standardMessageReducer } from './standardMessage/reducers';
import { standardMessagesReducer } from './standardMessages/reducers';

export const rootReducer = combineReducers({
    outbase: outbaseReducer,
    emergencyMessage: emergencyMessageReducer,
    standardMessage: standardMessageReducer,
    global: globalReducer,
    standardMessages: standardMessagesReducer
})

export type GlobalState = ReturnType<typeof rootReducer>;

export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	GlobalState,
	null,
	Action<string>
>;

const composeEnhancers = composeWithDevTools({
    name: 'Customer Messaging Portal',
})

export const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(ReduxThunk))
)