import { useSelector } from "react-redux";
import { GlobalState } from '../../store';
import './successMessage.css';

export function SuccessMessage() {

    const successMessage = useSelector(
        (state: GlobalState) => state.global.successMessage
    );

    return (
        <div id="successMessageContainer">
            {successMessage && <div className='row'>
                <span className='col-md-10 offset-md-1 success'><b>{successMessage}</b></span>
            </div>}
        </div>
    )
}

export default SuccessMessage