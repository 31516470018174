import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from '../../store';
import { setShowAdditionalComponent } from '../../store/emergencyMessage/actions';
import { setErrorMessage, setSuccessMessage } from '../../store/global/actions';
import './deleteemergencymessage.css';
import { deleteEmergencyMessage } from '../../services/emergencyMessageService';

export function DeleteEmergencyMessage() {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const showAdditionalComponent = useSelector(
        (state: GlobalState) => state.emergencyMessage.showAdditionalComponent
    );
    const country = useSelector(
        (state: GlobalState) => state.emergencyMessage.country
    );
    const errorMessage = useSelector(
        (state: GlobalState) => state.global.errorMessage
    );

    const userName = useSelector(
        (state: GlobalState) => state.global.userName
    );

    async function deleteMessage() {
        dispatch(setErrorMessage(""));

        setTimeout(() => {
            if (!response) {
                dispatch(setErrorMessage(t('emergencyMessage:cannotConnectToService')));
            }
        }, 10000);

        var response = await deleteEmergencyMessage(country, userName);

        if (response.status === 200) {
            dispatch(setShowAdditionalComponent(false))
            dispatch(setSuccessMessage(t('emergencyMessage:deletedSuccessfully')));
            setTimeout(() => {
                dispatch(setSuccessMessage(""));
            }, 5000);
        } else {
            dispatch(setErrorMessage(t('emergencyMessage:unableToDelete')));
        }
    }

    return (
        <div id='deleteEmergencyMessageContainer'>
            {showAdditionalComponent && errorMessage === "" &&
                <div id='emergencyMessageResponseContainer'>
                    <div className='row' id='submitDelete'>
                        <hr className="col-md-10 offset-md-1"></hr>
                        <span className='col-md-7 offset-md-1'><b>{t('emergencyMessage:doYouWantToDelete')}</b></span>
                        <button onClick={deleteMessage} className='col-md-2 btn btn-primary'>{t('emergencyMessage:yes')}</button>
                    </div>
                </div>}
        </div>
    )
}

export default DeleteEmergencyMessage