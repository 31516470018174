import { useTranslation } from 'react-i18next';
import GetEmergencyMessage from '../../components/emergencyMessage/getemergencymessage';
import DeleteEmergencyMessage from '../../components/emergencyMessage/deleteemergencymessage';
import { ErrorMessage } from '../../components/global/errorMessage';
import SuccessMessage from '../../components/global/successMessage';

export function DeleteEmergency() {

    const { t } = useTranslation();

    return (
        <div>
            <div className='row m-2 mt-3'>
                <h3 className='col-md-11 offset-md-1'>{t('emergencyMessage:deleteEmergencyMessage')}</h3>
                <p className='col-md-10 offset-md-1'>{t('emergencyMessage:chooseCountryMessage')}</p>
            </div>
            <div>
                <GetEmergencyMessage></GetEmergencyMessage>
                <DeleteEmergencyMessage></DeleteEmergencyMessage>
                <ErrorMessage></ErrorMessage>
                <SuccessMessage></SuccessMessage>
            </div>
        </div>
    )
}