import GetStandardMessages from '../../components/standardMessages/getstandardmessages';
import ErrorMessage from "../../components/global/errorMessage";
import SuccessMessage from "../../components/global/successMessage";
import { useTranslation } from 'react-i18next';

export function GetStandards() {

    const { t } = useTranslation();
    
    return (
        <div>
            <div className="row m-2 mt-3">
                <h3 className="col-md-11 offset-md-1">{t('standardLoadsMessage:getStandardMessages')}</h3>
                <p className="col-md-10 offset-md-1">{t('standardLoadsMessage:enterLoadNumbers')}</p>
            </div>
            <div>
                <GetStandardMessages></GetStandardMessages>
                <ErrorMessage></ErrorMessage>
                <SuccessMessage></SuccessMessage>
            </div>
        </div>
    )
}