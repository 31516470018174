import './App.css';
import { config } from './Config';
import { PublicClientApplication } from '@azure/msal-browser';

import { useDispatch } from 'react-redux';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import NavMenu from './components/global/navMenu';
import { setUserName } from './store/global/actions';

export function App() {

  const [error, setError] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  //Initialize the MSAL application object
  const msalConfig = {
    auth: {
      clientId: config.appId,
      redirectUri: config.redirectUri,
      authority: config.authority
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: true
    }
  }

  const msalInstance = new PublicClientApplication(msalConfig)


  async function login() {
    try {
      //Login via popup
      let msal = await msalInstance.loginPopup(
        {
          scopes: config.scopes,
          prompt: "select_account"
        });
      dispatch(setUserName(msal.idTokenClaims.name));
      setIsAuthenticated(true);
    }
    catch (err) {
      setIsAuthenticated(false);
      setError(err)
    }
  }

  return (
    <div>
      {isAuthenticated ?
        <NavMenu></NavMenu> :
        <div className="App">
          <div className="App-header">
            <p>
              <button className="btn btn-secondary btn-lg" onClick={login}>{t('app:login')}</button>
              <span>{error}</span>
            </p>
          </div>
        </div>
      }
    </div>
  )
}

export default App;
