import { SET_LOADS, SET_LOADS_RESPONSE, SET_SHOW_ADDITIONAL_COMPONENT, StandardMessagesActionType, StandardMessagesState } from "./types";

export const initialState: StandardMessagesState = {
    loads: "",
    loadResponse: [],
    showAdditionalComponent: false,
}

export function standardMessagesReducer(
    state = initialState,
    action: StandardMessagesActionType
): StandardMessagesState {
    switch (action.type) {
        case SET_LOADS:
            return {
                ...state,
                loads: action.payload,
            }
        case SET_LOADS_RESPONSE:
            return {
                ...state,
                loadResponse: action.payload,
            }
        case SET_SHOW_ADDITIONAL_COMPONENT:
            return {
                ...state,
                showAdditionalComponent: action.payload
            }
        default:
            return state;
    }
}