import { useState } from 'react';
import './updateemergencymessage.css';
import { postEmergencyMessage } from '../../services/emergencyMessageService';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from '../../store';
import { setErrorMessage, setSuccessMessage } from '../../store/global/actions';
import { setEmergencyMessageGlobal, setShowAdditionalComponent } from '../../store/emergencyMessage/actions';
import { EmergencyMessageModel } from './models/emergencyMessgeModel';

export function UpdateEmergencyMessage() {

    const [newTitle, setNewTitle] = useState('');
    const [newEmergencyMessage, setNewEmergencyMessage] = useState('');
    const [newLink, setNewLink] = useState('');
    const [newLinkText, setNewLinkText] = useState('');

    const [validNewTitle, setValidNewTitle] = useState(false);
    const [validNewMessage, setValidNewMessage] = useState(false);
    const [validNewLink, setValidNewLink] = useState(true);
    const [validNewLinkText, setValidNewLinkText] = useState(true);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const country = useSelector(
        (state: GlobalState) => state.emergencyMessage.country
    );
    const showAdditionalComponent = useSelector(
        (state: GlobalState) => state.emergencyMessage.showAdditionalComponent
    );

    const userName = useSelector(
        (state: GlobalState) => state.global.userName
    );

    const validTitle = RegExp(/^[-a-zA-ZäöüÄÖÜß0-9(). ]{5,50}$/);
    const validMessage = RegExp(/^[a-zA-ZäöüÄÖÜß0-9. ]{10,200}$/);
    //eslint-disable-next-line
    const validLink = RegExp(/^https?:\/\/(www\.)?[-a-zA-Z0-9äöüÄÖÜß@:%._\+~#=]{1,256}\.[a-zA-ZäöüÄÖÜß0-9()]{1,6}\b([-a-zA-ZäöüÄÖÜß0-9()@:%_\+.~#?&//=]*)/);
    const validLinkText = RegExp(/^[a-zA-ZäöüÄÖÜß. ]{5,50}$/);

    function regExCheck() {
        const title = validTitle.test(newTitle);
        const message = validMessage.test(newEmergencyMessage);
        if (title && message) {
            return true;
        } else {
            return false;
        }
    }

    function updateNewTitle(e: any) {
        setNewTitle(e.target.value);
        const title = validTitle.test(e.target.value);
        if (!title) {
            setValidNewTitle(false);
        } else {
            setValidNewTitle(true);
        }
    }

    function updateNewEmergencyMessage(e: any) {
        setNewEmergencyMessage(e.target.value);
        const message = validMessage.test(e.target.value);
        if (!message) {
            setValidNewMessage(false);
        } else {
            setValidNewMessage(true);
        }
    }

    function updateNewLink(e: any) {
        setNewLink(e.target.value);
        const link = validLink.test(e.target.value);
        if (link || e.target.value === "") {
            setValidNewLink(true);
        } else if (!link) {
            setValidNewLink(false);
        }
    }

    function updateNewLinkText(e: any) {
        setNewLinkText(e.target.value);
        const linkText = validLinkText.test(e.target.value);
        if (linkText || e.target.value === "") {
            setValidNewLinkText(true);
        } else {
            setValidNewLinkText(false);
        }
    }

    async function submitUpdate() {

        const regEx = regExCheck();
        if (regEx) {

            const emergencyMessage = {
                language: country,
                title: newTitle,
                emergencyMessage: newEmergencyMessage,
                link: newLink,
                linkText: newLinkText
            };

            dispatch(setErrorMessage(""));

            setTimeout(() => {
                if (!response) {
                    dispatch(setShowAdditionalComponent(false));
                    dispatch(setErrorMessage(t('emergencyMessage:cannotConnectToService')));
                }
            }, 10000);

            var response = await postEmergencyMessage(emergencyMessage, userName);

            if (response.status === 200) {
                const resJson = (await response.json()) as EmergencyMessageModel;
                dispatch(setEmergencyMessageGlobal(resJson));
                dispatch(setSuccessMessage(t('emergencyMessage:updatedSuccessfully')));
                setTimeout(() => {
                    dispatch(setSuccessMessage(""));
                    dispatch(setShowAdditionalComponent(false));
                }, 5000);
            } else {
                dispatch(setErrorMessage(t('emergencyMessage:cannotConnectToService')));
                dispatch(setShowAdditionalComponent(false));
            }
        } else {
            dispatch(setErrorMessage(t('app:detailsNotCorrect')));
            setTimeout(() => {
                dispatch(setErrorMessage(""));
            }, 5000)
        }

    }

    return (
        <div id='addUpdateEmergencyMessageContainer'>
            {showAdditionalComponent && <div id='showUpdateEmergencyMessage'>
                <div className='row mt-2'>
                    <hr className="col-md-10 offset-md-1"></hr>
                    <span className='col-md-11 offset-md-1'><b>{t('emergencyMessage:updateEmergencyMessageDetails')}</b></span>
                </div>
                <div className='row mt-4'>
                    <span className='col-md-2 offset-md-1'><b>{t('emergencyMessage:newTitle')}</b><span className="required">*</span></span>
                    <input type='text' onChange={updateNewTitle} className='col-md-8' placeholder={t('app:between5and50')}></input>
                    <div className="col-md-1">
                        {validNewTitle && <i className="valid bi bi-check-lg"></i>}
                        {!validNewTitle && <i className="notValid bi bi-x-lg"></i>}
                    </div>
                </div>
                <div className='row mt-2'>
                    <span className='col-md-2 offset-md-1'><b>{t('emergencyMessage:newEmergencyMessage')}</b><span className="required">*</span></span>
                    <input onChange={updateNewEmergencyMessage} className='col-md-8' placeholder={t('app:between10and200')}></input>
                    <div className="col-md-1">
                        {validNewMessage && <i className="valid bi bi-check-lg"></i>}
                        {!validNewMessage && <i className="notValid bi bi-x-lg"></i>}
                    </div>
                </div>
                <div className='row mt-2'>
                    <span className='col-md-2 offset-md-1'><b>{t('emergencyMessage:newLink')}</b></span>
                    <input onChange={updateNewLink} className='col-md-8' placeholder={t('emergencyMessage:validLinkOrEmpty')}></input>
                    <div className="col-md-1">
                        {validNewLink && <i className="valid bi bi-check-lg"></i>}
                        {!validNewLink && <i className="notValid bi bi-x-lg"></i>}
                    </div>
                </div>
                <div className='row mt-2'>
                    <span className='col-md-2 offset-md-1'><b>{t('emergencyMessage:newLinkText')}</b></span>
                    <input onChange={updateNewLinkText} className='col-md-8' placeholder={t('app:between5and50OrEmpty')}></input>
                    <div className="col-md-1">
                        {validNewLinkText && <i className="valid bi bi-check-lg"></i>}
                        {!validNewLinkText && <i className="notValid bi bi-x-lg"></i>}
                    </div>
                </div>
                <div className='row mt-2'>
                    <button className='col-md-2 offset-md-1 btn btn-primary' onClick={submitUpdate}>{t('emergencyMessage:submit')}</button>
                </div>
            </div>}
        </div>
    )
}

export default UpdateEmergencyMessage;