var baseUrl = process.env.REACT_APP_BASE_API_URL
var apiKey = process.env.REACT_APP_API_KEY;

export const getStandardMessage = (loadNumber: string) => {
    return fetch(baseUrl + 'standardMessages?Load=' + loadNumber, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': apiKey!
        }
    });
}

export const postStandardMessage = (load: string, message: string, order: string, userName: string) => {
    return fetch(baseUrl + 'standardMessages?userName=' + userName, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': apiKey!
        },
        body: JSON.stringify({load: load, message: message, orderNumber: order})
    });
}

export const deleteStandardMessage = (loadNumber: string, userName: string) => {
    return fetch(baseUrl + 'standardMessages?Load=' + loadNumber + '&userName=' + userName, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': apiKey!
        }
    });
}