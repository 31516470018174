var baseUrl = process.env.REACT_APP_BASE_API_URL
var apiKey = process.env.REACT_APP_API_KEY;

export const getStandardMessages = (loadNumbers: string) => {
    return fetch(baseUrl + 'MultipleLoadsStandardMessage?requests=' + loadNumbers, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': apiKey!
        },
    });
}

export const postStandardMessages = (loads: string[], message: string, userName: string) => {
    return fetch(baseUrl + 'MultipleLoadsStandardMessage?userName=' + userName, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': apiKey!
        },
        body: JSON.stringify({LoadNumbers: loads, Message: message})
    });
}

export const deleteStandardMessages = (loadNumbers: string[], userName: string) => {
    return fetch(baseUrl + 'MultipleLoadsStandardMessage?userName=' + userName, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': apiKey!
        },
        body: JSON.stringify(loadNumbers)
    });
}