import './updatestandardmessages.css';
import { postStandardMessages } from '../../services/standardMessagesService';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from '../../store';
import { setLoadResponse, setShowAdditionalComponent } from '../../store/standardMessages/actions';
import { setErrorMessage, setSuccessMessage } from '../../store/global/actions';
import { StandardMessageModel } from './models/standardMessageModel';
import StandardMessageList from '../global/standardMessageList';

export function UpdateStandardMessages() {

    const showAdditionalComponent = useSelector(
        (state: GlobalState) => state.standardMessages.showAdditionalComponent
    );
    const loads = useSelector(
        (state: GlobalState) => state.standardMessages.loads
    );

    const userName = useSelector(
        (state: GlobalState) => state.global.userName
    );

    const messageSelected = useSelector(
        (state: GlobalState) => state.global.newStandardMessage
    );

    const { t } = useTranslation();
    const dispatch = useDispatch();

    async function updateLoadsMessage() {

        if (messageSelected === "") {
            dispatch(setErrorMessage(t("standardLoadMessage:mustSelectAMessage")));
        } else {
            const loadsNoWhitespace = loads.replaceAll(' ', '');
            var loadArray = loadsNoWhitespace.split(',');

            setTimeout(() => {
                if (!response) {
                    dispatch(setShowAdditionalComponent(false));
                    dispatch(setErrorMessage(t('standardLoadMessage:cannotConnectToService')));
                }
            }, 10000);

            var response = await postStandardMessages(loadArray, messageSelected, userName);

            const resJson = (await response.json()) as StandardMessageModel[];

            if (resJson.length === 0) {
                dispatch(setShowAdditionalComponent(false));
                dispatch(setErrorMessage(t('standardLoadMessage:cannotConnectToService')));
            } else {
                dispatch(setLoadResponse(resJson));
                dispatch(setShowAdditionalComponent(false));
                dispatch(setSuccessMessage(t('standardLoadMessage:successUpdateStandardMessage')));
                setTimeout(() => {
                    dispatch(setSuccessMessage(t('')));
                }, 5000);
            }
        }
    }

    return (
        <div id='addUpdateStandardMessagesContainer'>
            {showAdditionalComponent &&
                <div className='row'>
                    <span className='col-md-10 offset-md-1'><b>{t('standardLoadsMessage:enterMessage')}</b></span>
                    <StandardMessageList></StandardMessageList>
                    <div className='col-md-2 offset-md-1'>
                        <button className='btn btn-primary button' onClick={updateLoadsMessage}>{t('standardLoadsMessage:submit')}</button>
                    </div>
                </div>}
        </div>
    )
}

export default UpdateStandardMessages