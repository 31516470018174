import { EmergencyMessageModel } from "../../components/emergencyMessage/models/emergencyMessgeModel";

export interface EmergencyMessageState {
    emergencyMessage: EmergencyMessageModel;
    country: string;
    showAdditionalComponent: boolean;
}

export const SET_EMERGENCY_MESSAGE = "SET_EMERGENCY_MESSAGE";
export const SET_COUNTRY = "SET_COUNTRY";
export const SET_SHOW_ADDITIONAL_COMPONENT = "SET_SHOW_ADDITIONAL_COMPONENT";

export interface SetEmergencyMessage {
    type: typeof SET_EMERGENCY_MESSAGE;
    payload: EmergencyMessageState['emergencyMessage'];
}

export interface SetCountry {
    type: typeof SET_COUNTRY;
    payload: EmergencyMessageState['country'];
}

export interface SetShowAdditionalComponent {
    type: typeof SET_SHOW_ADDITIONAL_COMPONENT,
    payload: EmergencyMessageState['showAdditionalComponent'];
}

export type EmergencyMessageActionType =
    SetEmergencyMessage
    | SetCountry
    | SetShowAdditionalComponent;