import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { StandardMessageModel } from '../../components/standardMessage/models/standardMessageModel';
import { postStandardMessages } from '../../services/standardMessagesService';
import { GlobalState } from '../../store';
import { setLoadMessage, setShowAdditionalComponent } from '../../store/outbase/actions';
import { setErrorMessage } from '../../store/global/actions';
import './updateOutbaseMessage.css';
import StandardMessageList from '../global/standardMessageList';

export function UpdateOutbaseMessage() {

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const showComponent = useSelector(
        (state: GlobalState) => state.outbase.showAdditionalComponent
    );
    const loadList = useSelector(
        (state: GlobalState) => state.outbase.loadList
    );

    const messageSelected = useSelector(
        (state: GlobalState) => state.global.newStandardMessage
    );

    const userName = useSelector(
        (state: GlobalState) => state.global.userName
    );

    async function submit() {
        if (messageSelected === "") {
            dispatch(setErrorMessage(t("outbaseMessage:mustSelectAMessage")));
        } else {
            setTimeout(() => {
                if (!response) {
                    dispatch(setErrorMessage(t('standardLoadMessage:cannotConnectToService')));
                }
            }, 10000);
            var response = await postStandardMessages(loadList, messageSelected, userName);
            const resJson = (await response.json()) as StandardMessageModel[];
            dispatch(setLoadMessage(resJson));
            dispatch(setShowAdditionalComponent(false));
        }
    }

    return (
        <div>
            {showComponent &&
                <div>
                    <div className="row m-2">
                        <p className="col-md-11 offset-md-1"><b>{t("outbaseMessage:enterMessageToAdd")}</b></p>
                        <div className="col-md-10 offset-md-1">
                            <StandardMessageList></StandardMessageList>
                        </div>
                    </div>
                    <div className="row m-2 mt-4">
                        <div className="col-md-9 offset-md-1">
                            <p><b>{t("outbaseMessage:updateOutbaseMessage")}</b></p>
                        </div>
                        <div className="col-md-1">
                            <button className="btn btn-primary" onClick={submit}>{t("outbaseMessage:submit")}</button>
                        </div>
                    </div>
                </div>}
        </div>
    )
}

export default UpdateOutbaseMessage;