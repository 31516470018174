import { useTranslation } from "react-i18next";
import GetEmergencyMessage from "../../components/emergencyMessage/getemergencymessage";
import UpdateEmergencyMessage from "../../components/emergencyMessage/updateemergencymessage";
import ErrorMessage from "../../components/global/errorMessage";
import SuccessMessage from "../../components/global/successMessage";

export function UpdateEmergency() {

    const { t } = useTranslation();
    
    return (
        <div>
            <div className='row m-2 mt-3'>
                <h3 className='col-md-11 offset-md-1'>{t('emergencyMessage:addUpdateEmergencyMessage')}</h3>
                <p className='col-md-10 offset-md-1'>{t('emergencyMessage:addUpdateChooseCountry')}</p>
            </div>
            <div>
                <GetEmergencyMessage></GetEmergencyMessage>
                <UpdateEmergencyMessage></UpdateEmergencyMessage>
                <ErrorMessage></ErrorMessage>
                <SuccessMessage></SuccessMessage>
            </div>
        </div>
    )
}