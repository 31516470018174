import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { deleteStandardMessages } from '../../services/standardMessagesService';
import { GlobalState } from '../../store';
import { setErrorMessage, setSuccessMessage } from '../../store/global/actions';
import { setLoadList, setLoadMessage, setShowAdditionalComponent } from '../../store/outbase/actions';
import './deleteOutbaseMessage.css';

export function DeleteOutbaseMessage() {

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const showComponent = useSelector(
        (state: GlobalState) => state.outbase.showAdditionalComponent
    );
    const loadList = useSelector(
        (state: GlobalState) => state.outbase.loadList
    );
    const errorMessage = useSelector(
        (state: GlobalState) => state.global.errorMessage
    );

    const userName = useSelector(
        (state: GlobalState) => state.global.userName
    );

    async function deleteOutbaseMessage() {
        setTimeout(() => {
            if (!response || response.status === 404) {
                dispatch(setErrorMessage(t('standardLoadMessage:cannotConnectToService')));
            }
        }, 10000);

        var response = await deleteStandardMessages(loadList, userName);

        if(response.status === 200) {
            dispatch(setLoadMessage([]));
            dispatch(setLoadList([]));
            dispatch(setSuccessMessage(t("outbaseMessage:successfullyDeleted")));
            dispatch(setShowAdditionalComponent(false));
            setTimeout(() => {
                dispatch(setSuccessMessage(""));
            }, 5000);
        } else {
            dispatch(setErrorMessage("standardLoadMessage:cannotConnectToService"))
        }
    }

    return (
        <div id="deleteOutbaseContainer">
            {showComponent && errorMessage === "" &&
                <div className="row">
                    <span className="col-md-8 offset-md-1"><b>{t("outbaseMessage:deleteMessages")}</b></span>
                    <button className="col-md-1 offset-md-1 btn btn-primary" onClick={deleteOutbaseMessage}>{t("outbaseMessage:submit")}</button>
                </div>
            }
        </div>
    )
}

export default DeleteOutbaseMessage;