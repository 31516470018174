import './getemergencymessage.css';
import { getEmergencyMessage } from '../../services/emergencyMessageService';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setErrorMessage } from '../../store/global/actions';
import { setCountry, setEmergencyMessageGlobal, setShowAdditionalComponent } from '../../store/emergencyMessage/actions';
import { GlobalState } from '../../store';
import { EmergencyMessageModel } from './models/emergencyMessgeModel';

export function GetEmergencyMessage() {

    const emergencyMessage = useSelector(
        (state: GlobalState) => state.emergencyMessage.emergencyMessage
    );
    const country = useSelector(
        (state: GlobalState) => state.emergencyMessage.country
    );

    const [showGetEmergencyMessage, setShowGetEmergencyMessage] = useState(false);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setErrorMessage(""));
        dispatch(setShowAdditionalComponent(false));
        dispatch(setCountry("ENG"));
    }, [dispatch]);

    function updateCountry(e: any) {
        dispatch(setCountry(e.target.value));
    }

    async function getMessage() {
        setShowGetEmergencyMessage(false);
        dispatch(setErrorMessage(""));
        dispatch(setShowAdditionalComponent(true));

        setTimeout(() => {
            if (!response) {
                setShowGetEmergencyMessage(false);
                dispatch(setErrorMessage(t('emergencyMessage:cannotConnectToService')))
            }
        }, 10000);

        var response = await getEmergencyMessage(country);

        if (response.status === 204) {
            setShowGetEmergencyMessage(false);
            dispatch(setErrorMessage(t('emergencyMessage:noEmergencyMessage')));
        }
        else if (response.status === 200) {
            const resJson = (await response.json()) as EmergencyMessageModel;

            dispatch(setEmergencyMessageGlobal(resJson));
            setShowGetEmergencyMessage(true);
        }
    }

    return (
        <div id='getEmergencyMessageContainer' >
            <div className='row'>
                <span className='col-md-3 offset-md-1'><b>{t('emergencyMessage:chooseCountry')}</b></span>
                <select id='select' className='col-md-2 offset-md-1' onChange={updateCountry}>
                    <option value='ENG'>{t('outbaseMessage:england')}</option>
                    <option value='GER'>{t('outbaseMessage:germany')}</option>
                </select>
                <button id='getMessage' onClick={getMessage} className='col-md-3 offset-md-1 btn btn-success'>{t('emergencyMessage:getEmergencyMessage')}</button>
            </div>
            {showGetEmergencyMessage && <div id='emergencyMessageResponseContainer' className="mt-4">
                <div className='row'>
                    <span className='col-md-2 offset-md-1'><b>{t('emergencyMessage:language')}</b></span>
                    <span className='col-md-8'>{emergencyMessage.language}</span>
                    <hr className='col-md-10 offset-md-1'></hr>
                </div>
                <div className='row'>
                    <span className='col-md-2 offset-md-1'><b>{t('emergencyMessage:title')}</b></span>
                    <span className='col-md-8'>{emergencyMessage.title}</span>
                    <hr className='col-md-10 offset-md-1'></hr>
                </div>
                <div className='row'>
                    <span className='col-md-2 offset-md-1'><b>{t('emergencyMessage:emergencyMessage')}</b></span>
                    <span className='col-md-8'>{emergencyMessage.emergencyMessage}</span>
                    <hr className='col-md-10 offset-md-1'></hr>
                </div>
                <div className='row'>
                    <span className='col-md-2 offset-md-1'><b>{t('emergencyMessage:link')}</b></span>
                    <span className='col-md-8'>{emergencyMessage.link}</span>
                    <hr className='col-md-10 offset-md-1'></hr>
                </div>
                <div className='row'>
                    <span className='col-md-2 offset-md-1'><b>{t('emergencyMessage:linkText')}</b></span>
                    <span className='col-md-8'>{emergencyMessage.linkText}</span>
                </div>
            </div>}
        </div>
    )
}

export default GetEmergencyMessage