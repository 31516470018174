import { SET_MESSAGE, SET_LOAD_NUMBER, SET_SHOW_ADDITIONAL_COMPONENT, StandardMessageActionType } from "./types";

export function setLoadNumber(loadNumber: string): StandardMessageActionType {
    return {
        type: SET_LOAD_NUMBER,
        payload: loadNumber,
    }
}

export function setMessage(message: string): StandardMessageActionType {
    return {
        type: SET_MESSAGE,
        payload: message,
    }
}

export function setShowAdditionalComponent(showAdditionalComponent: boolean): StandardMessageActionType {
    return {
        type: SET_SHOW_ADDITIONAL_COMPONENT,
        payload: showAdditionalComponent
    }
}