import appDE from './de/app.json';
import appEN from './en/app.json';
import emergencyMessageEN from './en/emergencyMessage.json';
import emergencyMessageDE from './de/emergencyMessage.json';
import standardLoadMessageEN from './en/standardLoadMessage.json';
import standardLoadMessageDE from './de/standardLoadMessage.json';
import standardLoadsMessageEN from './en/standardLoadsMessage.json';
import standardLoadsMessageDE from './de/standardLoadsMessage.json';
import outbaseMessagesEN from './en/outbaseMessage.json';
import outbaseMessagesDE from './de/outbaseMessage.json';
import standardMessageListEN from './en/standardMessageList.json';
import standardMessageListDE from './de/standardMessageList.json';

const resources = {
	en: {
		app: appEN,
		emergencyMessage: emergencyMessageEN,
		standardLoadMessage: standardLoadMessageEN,
		standardLoadsMessage: standardLoadsMessageEN,
		outbaseMessage: outbaseMessagesEN,
		standardMessageList: standardMessageListEN
	},
	de: {
		app: appDE,
		emergencyMessage: emergencyMessageDE,
		standardLoadMessage: standardLoadMessageDE,
		standardLoadsMessage: standardLoadsMessageDE,
		outbaseMessage: outbaseMessagesDE,
		standardMessageList: standardMessageListDE
	},
};

export default resources;