import { StandardMessageModel } from "../../components/standardMessages/models/standardMessageModel";

export interface StandardMessagesState {
    loads: string,
    loadResponse: StandardMessageModel[],
    showAdditionalComponent: boolean
}

export const SET_LOADS = "SET_LOADS";
export const SET_LOADS_RESPONSE = "SET_LOADS_RESPONSE";
export const SET_SHOW_ADDITIONAL_COMPONENT = "SET_SHOW_ADDITIONAL_COMPONENT";

export interface SetLoads {
    type: typeof SET_LOADS;
    payload: StandardMessagesState['loads'];
}

export interface SetLoadsResponse {
    type: typeof SET_LOADS_RESPONSE;
    payload: StandardMessagesState['loadResponse'];
}

export interface SetShowAdditionalComponent {
    type: typeof SET_SHOW_ADDITIONAL_COMPONENT;
    payload: StandardMessagesState['showAdditionalComponent'];
}

export type StandardMessagesActionType = 
    SetLoads
    | SetLoadsResponse
    | SetShowAdditionalComponent;