import { LoadMessage } from '../../components/outbase/models/loadMessage';

export interface OutbaseState {
    outbase: string;
    loadMessage: LoadMessage[];
    showAdditionalComponent: boolean,
    loadList: string[];
}

export const SET_OUTBASE = "SET_OUTBASE";
export const SET_LOAD_MESSAGE = "SET_LOAD_MESSAGE";
export const SET_SHOW_ADDITIONAL_COMPONENT = "SET_SHOW_ADDITIONAL_COMPONENT";
export const SET_LOAD_LIST = "SET_LOAD_LIST";

export interface SetOutbase {
    type: typeof SET_OUTBASE;
    payload: OutbaseState['outbase'];
}

export interface SetLoadMessage {
    type: typeof SET_LOAD_MESSAGE;
    payload: OutbaseState['loadMessage'];
}

export interface SetShowAdditionalComponent {
    type: typeof SET_SHOW_ADDITIONAL_COMPONENT;
    payload: OutbaseState['showAdditionalComponent'];
}

export interface SetLoadList {
    type: typeof SET_LOAD_LIST;
    payload: OutbaseState['loadList'];
}

export type OutbaseActionType =
    SetOutbase
    | SetLoadMessage
    | SetShowAdditionalComponent
    | SetLoadList;