import { OutbaseActionType, OutbaseState, SET_LOAD_MESSAGE, SET_OUTBASE, SET_SHOW_ADDITIONAL_COMPONENT, SET_LOAD_LIST } from "./types";

export const initialState: OutbaseState = {
    outbase: '',
    loadMessage: [],
    showAdditionalComponent: false,
    loadList: [],
}

export function outbaseReducer(
    state = initialState,
    action: OutbaseActionType
): OutbaseState {
    switch (action.type) {
        case SET_OUTBASE:
            return {
                ...state,
                outbase: action.payload,
            };
        case SET_LOAD_MESSAGE:
            return {
                ...state,
                loadMessage: action.payload,
            }
        case SET_SHOW_ADDITIONAL_COMPONENT:
            return {
                ...state,
                showAdditionalComponent: action.payload
            }
        case SET_LOAD_LIST:
            return {
                ...state,
                loadList: action.payload
            }
        default:
            return state;
    }
}