import { StandardMessageModel } from "../../components/standardMessages/models/standardMessageModel";
import { SET_LOADS, SET_LOADS_RESPONSE, SET_SHOW_ADDITIONAL_COMPONENT, StandardMessagesActionType } from "./types";

export function setLoads(loads: string): StandardMessagesActionType {
    return {
        type: SET_LOADS,
        payload: loads,
    }
}

export function setLoadResponse(loadResponse: StandardMessageModel[]): StandardMessagesActionType {
    return {
        type: SET_LOADS_RESPONSE,
        payload: loadResponse,
    }
}

export function setShowAdditionalComponent(showAdditionalComponent: boolean): StandardMessagesActionType {
    return {
        type: SET_SHOW_ADDITIONAL_COMPONENT,
        payload: showAdditionalComponent,
    }
}