import i18n from 'i18next';
import LngDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import resources from './locales';

i18n
	.use(LngDetector)
	.use(initReactI18next)
	.init({
		resources,
		lng: 'en',
		fallbackLng: ['en', 'de'],
		keySeparator: false,
		debug: false,
		interpolation: {
			escapeValue: false,
		},
	});

export default i18n;
