import { LoadMessage } from '../../components/outbase/models/loadMessage';
import { OutbaseActionType, SET_LOAD_LIST, SET_LOAD_MESSAGE, SET_OUTBASE, SET_SHOW_ADDITIONAL_COMPONENT } from "./types";

export function setOutbaseString(outbase: string): OutbaseActionType {
    return {
        type: SET_OUTBASE,
        payload: outbase,
    }
}

export function setLoadMessage(loadMessage: LoadMessage[]): OutbaseActionType {
    return {
        type: SET_LOAD_MESSAGE,
        payload: loadMessage
    }
}

export function setShowAdditionalComponent(showAdditionalComponent: boolean): OutbaseActionType {
    return {
        type: SET_SHOW_ADDITIONAL_COMPONENT,
        payload: showAdditionalComponent
    }
}

export function setLoadList(loadList: string[]): OutbaseActionType {
    return {
        type: SET_LOAD_LIST,
        payload: loadList
    }
}