export interface GlobalState {
    errorMessage: string;
    successMessage: string;
    userName: string;
    newStandardMessage: string;
}

export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
export const SET_SUCCESS_MESSAGE = "SET_SUCCESS_MESSAGE";
export const SET_USERNAME = "SET_USERNAME";
export const SET_NEW_STANDARD_MESSAGE = "SET_NEW_STANDARD_MESSAGE";

export interface SetErrorMessage {
    type: typeof SET_ERROR_MESSAGE;
    payload: GlobalState['errorMessage']
}

export interface SetSuccessMessage {
    type: typeof SET_SUCCESS_MESSAGE;
    payload: GlobalState['successMessage']
}

export interface SetUsername {
    type: typeof SET_USERNAME;
    payload: GlobalState['userName']
}

export interface SetNewStandardMessage {
    type: typeof SET_NEW_STANDARD_MESSAGE;
    payload: GlobalState['newStandardMessage']
}

export type GlobalActionType =
    SetErrorMessage
    | SetSuccessMessage
    | SetUsername
    | SetNewStandardMessage;