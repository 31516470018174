import { useTranslation } from 'react-i18next';
import GetStandardMessage from '../../components/standardMessage/getstandardmessage';
import UpdateStandardMessage from '../../components/standardMessage/updatestandardmessage';
import ErrorMessage from "../../components/global/errorMessage";
import SuccessMessage from "../../components/global/successMessage";

export function UpdateStandard() {

    const { t } = useTranslation();
    
    return (
        <div>
            <div className="row m-2 mt-3">
                <h3 className='col-md-11 offset-md-1'>{t('standardLoadMessage:addUpdateStandardMessage')}</h3>
                <p className='col-md-11 offset-md-1'>{t('standardLoadMessage:addUpdateStandardMessageMessage')}</p>
            </div>
            <div>
                <GetStandardMessage></GetStandardMessage>
                <UpdateStandardMessage></UpdateStandardMessage>
                <ErrorMessage></ErrorMessage>
                <SuccessMessage></SuccessMessage>
            </div>
        </div>
    )
}