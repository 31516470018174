import { EmergencyMessageModel } from '../components/emergencyMessage/models/emergencyMessgeModel';

var baseUrl = process.env.REACT_APP_BASE_API_URL;
var apiKey = process.env.REACT_APP_API_KEY;

export const getEmergencyMessage = (country: string) => {
    return fetch(baseUrl + 'GetEmergencyMessage?country=' + country, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': apiKey!
        }
    });
}

export const postEmergencyMessage = (emergencyMessage: EmergencyMessageModel, userName: string) => {
    return fetch(baseUrl + 'GetEmergencyMessage?country=' + emergencyMessage.language + "&userName=" + userName, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': apiKey!
        },
        body: JSON.stringify(emergencyMessage)
    });
}

export const deleteEmergencyMessage = (country: string, userName: string) => {
    return fetch(baseUrl + 'GetEmergencyMessage?country=' + country + "&userName=" + userName, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': apiKey!
        }
    });
}