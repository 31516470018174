import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from '../../store';
import { useTranslation } from 'react-i18next';
import { setNewStandardMessage } from '../../store/global/actions';
import './standardMessageList.css';
import { ListModel } from "./models/listModel";

export function StandardMessageList() {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const messageSelected = useSelector(
        (state: GlobalState) => state.global.newStandardMessage
    );

    useEffect(() => {
        var messageList = t('standardMessageList:standardMessageList', { returnObjects: true }) as ListModel[];
        setListOfMessages(messageList);
        dispatch(setNewStandardMessage(""));
    }, [t, dispatch]);

    let array: any[] | (() => any[]) = []

    const [listOfMessages, setListOfMessages] = useState(array);

    function newMessage(e: any) {
        dispatch(setNewStandardMessage(e.target.value));
    }

    return (
        <div className='row mt-1'>
            <label className='col-md-3 offset-md-1 mt-2'><b>{t('standardLoadMessage:message')}</b></label>
            <select onChange={newMessage} className="col-md-6 select">
                {listOfMessages.map(message =>
                    <option className="option" value={message.message} key={message.title}>{message.title}</option>
                )}
            </select>
            <span className="col-md-6 offset-md-4 mt-3">{messageSelected}</span>
        </div>
    )
}

export default StandardMessageList

