import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import './getstandardmessage.css';
import { getStandardMessage } from '../../services/standardMessageService';
import { setMessage, setLoadNumber, setShowAdditionalComponent } from '../../store/standardMessage/actions';
import { setErrorMessage } from '../../store/global/actions';
import { GlobalState } from '../../store';
import { StandardMessageModel } from './models/standardMessageModel';

export function GetStandardMessage() {
    const [showGetLoadMessage, setShowGetLoadMessage] = useState(false);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const loadNumber = useSelector(
        (state: GlobalState) => state.standardMessage.loadNumber
    );
    const message = useSelector(
        (state: GlobalState) => state.standardMessage.message
    );
    const [validLoadNumber, setValidLoadNumber] = useState(false);

    const validNumber = RegExp(/^[-a-zA-Z0-9]{2,12}$/);

    useEffect(() => {
        dispatch(setShowAdditionalComponent(false));
        dispatch(setLoadNumber(""));
        dispatch(setMessage(""));
        dispatch(setErrorMessage(""));
    }, [dispatch])

    function updateLoadNumber(e: any) {
        dispatch(setLoadNumber(e.target.value));
        const loadNumber = validNumber.test(e.target.value);
        if (!loadNumber) {
            setValidLoadNumber(false);
        } else {
            setValidLoadNumber(true);
        }
    }

    async function getLoadMessage() {
        setShowGetLoadMessage(false);
        dispatch(setErrorMessage(""));

        if (validLoadNumber) {
            if (loadNumber === "") {
                dispatch(setErrorMessage(t('standardLoadsMessage:enterLoadNumbersError')));
            } else {
                setTimeout(() => {
                    if (!response || response.status === 404) {
                        setShowGetLoadMessage(false);
                        dispatch(setErrorMessage(t('standardLoadMessage:cannotConnectToService')));
                    }
                }, 10000);

                var response = await getStandardMessage(loadNumber);

                if (response.status === 204) {
                    setShowGetLoadMessage(false);
                    dispatch(setErrorMessage(t('standardLoadMessage:noStandardMessage')));
                    dispatch(setShowAdditionalComponent(true));
                }
                else if (response.status === 200) {
                    dispatch(setErrorMessage(""));
                    setShowGetLoadMessage(true);
                    dispatch(setShowAdditionalComponent(true));
                    const resJson = (await response.json()) as StandardMessageModel;

                    dispatch(setLoadNumber(resJson.loadNumber));
                    dispatch(setMessage(resJson.message));
                }
            }
        } else {
            dispatch(setErrorMessage(t("app:validLoadNumber")));
        }
    }

    return (
        <div id='getStandardMessageContainer'>
            <div className='row' id='loadInput'>
                <label className='col-md-2 offset-md-1'><b>{t('standardLoadMessage:loadNumber')}</b></label>
                <input type='text' onChange={updateLoadNumber} className='col-md-3 offset-md-1' placeholder={t('app:between2and12')}></input>
                <div className="col-md-1 mt-2">
                    {validLoadNumber && <i className="valid bi bi-check-lg"></i>}
                    {!validLoadNumber && <i className="notValid bi bi-x-lg"></i>}
                </div>
                <button id='getStandardMessageSubmit' onClick={getLoadMessage} className='col-md-2 offset-md-1 btn btn-success'>{t('standardLoadMessage:getStandardMessage')}</button>
            </div>
            {showGetLoadMessage && <div id='standardMessage'>
                <div className='row'>
                    <label className='col-md-2 offset-md-1'><b>{t('standardLoadMessage:loadNumber')}</b></label>
                    <span className='col-md-8'>{loadNumber}</span>
                </div>
                <div className='row'>
                    <label className='col-md-2 offset-md-1'><b>{t('standardLoadMessage:message')}</b></label>
                    <span className='col-md-8'>{message}</span>
                </div>
            </div>}
        </div>
    )
}

export default GetStandardMessage