import GetOutbaseMessage from '../../components/outbase/getOutbaseMessage';
import DeleteOutbaseMessage from '../../components/outbase/deleteOutbaseMessage';
import ErrorMessage from '../../components/global/errorMessage';
import SuccessMessage from '../../components/global/successMessage';
import { useTranslation } from 'react-i18next';

export function DeleteOutbase() {

    const { t } = useTranslation();

    return (
        <div>
            <div className="row m-2 mt-3">
                <h3 className="col-md-11 offset-md-1">{t("outbaseMessage:deleteLoadMessageOutbase")}</h3>
                <p className="col-md-11 offset-md-1">{t("outbaseMessage:getOutbasesTitle")}</p>
            </div>
            <div>
                <GetOutbaseMessage></GetOutbaseMessage>
                <DeleteOutbaseMessage></DeleteOutbaseMessage>
                <ErrorMessage></ErrorMessage>
                <SuccessMessage></SuccessMessage>
            </div>
        </div>
    )
}