import { useTranslation } from 'react-i18next';
import ErrorMessage from '../../components/global/errorMessage';
import GetOutbaseMessage from '../../components/outbase/getOutbaseMessage';

export function GetOutbase() {

    const { t } = useTranslation();
    
    return (
        <div>
            <div className="row m-2 mt-3">
                <h3 className="col-md-11 offset-md-1">{t("outbaseMessage:loadsAndMessagesOutbase")}</h3>
                <p className="col-md-11 offset-md-1">{t("outbaseMessage:getOutbasesTitle")}</p>
            </div>
            <div>
                <GetOutbaseMessage></GetOutbaseMessage>
                <ErrorMessage></ErrorMessage>
            </div>
        </div>
    )
}