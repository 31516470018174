import { SET_MESSAGE, SET_LOAD_NUMBER, SET_SHOW_ADDITIONAL_COMPONENT, StandardMessageActionType, StandardMessageState } from "./types";

export const initialState: StandardMessageState = {
    loadNumber: "",
    message: "",
    showAdditionalComponent: false,
}

export function standardMessageReducer(
    state = initialState,
    action: StandardMessageActionType
): StandardMessageState {
    switch (action.type) {
        case SET_LOAD_NUMBER:
            return {
                ...state,
                loadNumber: action.payload,
            }
        case SET_MESSAGE:
            return {
                ...state,
                message: action.payload,
            }
        case SET_SHOW_ADDITIONAL_COMPONENT:
            return {
                ...state,
                showAdditionalComponent: action.payload
            }
        default:
            return state;
    }
}