import { useTranslation } from 'react-i18next';
import GetEmergencyMessage from '../../components/emergencyMessage/getemergencymessage';
import { ErrorMessage } from '../../components/global/errorMessage';

export function GetEmergency() {

    const { t } = useTranslation();

    return (
        <div>
            <div className='row m-2 mt-3'>
                <h3 className='col-md-11 offset-md-1'>{t('emergencyMessage:getEmergencyMessage')}</h3>
                <p className='col-md-10 offset-md-1'>{t('emergencyMessage:getEmergencyMessageMessage')}</p>
            </div>
            <div>
                <GetEmergencyMessage></GetEmergencyMessage>
                <ErrorMessage></ErrorMessage>
            </div>
        </div>
    )
}