import { useSelector } from "react-redux";
import { GlobalState } from '../../store';
import './errorMessage.css';

export function ErrorMessage() {

    const errorMessage = useSelector(
        (state: GlobalState) => state.global.errorMessage
    );

    return (
        <div id="errorMessageContainer">
            {errorMessage && <div className='row'>
                <span className='col-md-10 offset-md-1 error'><b>{errorMessage}</b></span>
            </div>}
        </div>
    )
}

export default ErrorMessage