import { useEffect, useState } from 'react';
import './getOutbaseMessage.css';
import { GlobalState } from '../../store';
import { setLoadList, setLoadMessage, setOutbaseString, setShowAdditionalComponent } from '../../store/outbase/actions';
import { setErrorMessage } from '../../store/global/actions';
import { GetOutbases, GetLoadsForOutbase } from '../../services/outbaseService';
import { getStandardMessages } from '../../services/standardMessagesService';
import { StandardMessageModel } from '../../components/standardMessage/models/standardMessageModel';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Depots } from './models/depots';
import { Crews } from './models/crews';

export function GetOutbaseMessage() {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [outbases, setOutbases] = useState<string[]>([]);
    const [country, setCountry] = useState("");
    const outbaseName = useSelector(
        (state: GlobalState) => state.outbase.outbase
    );
    const loadMessage = useSelector(
        (state: GlobalState) => state.outbase.loadMessage
    );

    useEffect(() => {
        dispatch(setLoadMessage([]));
        dispatch(setShowAdditionalComponent(false));
        dispatch(setErrorMessage(""));
    }, [dispatch]);

    async function selectEnglish() {

        dispatch(setErrorMessage(""));
        dispatch(setShowAdditionalComponent(false));

        setTimeout(() => {
            if (!response || response.status === 404) {
                dispatch(setErrorMessage(t('standardLoadMessage:cannotConnectToService')));
            }
        }, 10000);

        dispatch(setShowAdditionalComponent(false));
        dispatch(setLoadMessage([]));
        setOutbases([]);
        var response = await GetOutbases("ENG");
        const resJson = (await response.json()) as Depots;
        var result = setArray(resJson);
        setOutbases(result);
        setCountry("ENG");
    }

    async function selectGerman() {
        dispatch(setErrorMessage(""));
        dispatch(setShowAdditionalComponent(false));

        setTimeout(() => {
            if (!response || response.status === 404) {
                dispatch(setErrorMessage(t('standardLoadMessage:cannotConnectToService')));
            }
        }, 10000);

        dispatch(setShowAdditionalComponent(false));
        dispatch(setLoadMessage([]));
        setOutbases([]);
        var response = await GetOutbases("GER");
        const resJson = (await response.json()) as Depots;
        var result = setArray(resJson);
        setOutbases(result);
        setCountry("GER");
    }

    function setArray(response: Depots) {
        var result = new Array<string>();
        response.depots.forEach(depot => {
            result.push(depot.depot);
        });
        return result;
    }

    function setOutbase(e: any) {
        dispatch(setShowAdditionalComponent(false));
        dispatch(setLoadMessage([]));
        dispatch(setErrorMessage(""));
        dispatch(setOutbaseString(e.target.value));
    }

    async function getOutbaseLoads() {
        if (outbaseName === "" || outbaseName === "Please Select...." || country === "") {
            dispatch(setErrorMessage(t("outbaseMessage:selectCountry")));
            dispatch(setShowAdditionalComponent(false));
            dispatch(setLoadMessage([]));
        } else {

            setTimeout(() => {
                if (!response) {
                    dispatch(setErrorMessage(t('standardLoadMessage:cannotConnectToService')));
                }
            }, 10000);

            var response = await GetLoadsForOutbase(country, outbaseName);

            const resJson = (await response.json()) as Crews;

            if (resJson.crews.length === 0) {
                dispatch(setErrorMessage(t("outbaseMessage:noLoadsOutbase")));
            } else {
                setLoadsAndMessages(resJson);
            }
        }
    }

    async function setLoadsAndMessages(outbaseLoads: Crews) {
        var loads = new Array<string>();
        outbaseLoads.crews.forEach(load => {
            loads.push(load.load);
        });

        dispatch(setLoadList(loads));

        var response = await getStandardMessages(loads.toString());

        const resJson = (await response.json()) as StandardMessageModel[];

        dispatch(setShowAdditionalComponent(true));

        if (resJson.length === 0) {
            dispatch(setErrorMessage(t("outbaseMessage:noMessagesForLoads")));
        } else {
            dispatch(setLoadMessage(resJson));
        }
    }

    return (
        <div id="getOutbasesContainer">
            <div className="row buttons">
                <button className="col-md-2 offset-md-1 btn btn-success mt-1" onClick={selectEnglish}>{t("outbaseMessage:england")}</button>
                <button className="col-md-2 btn btn-success mt-1 ml-1" onClick={selectGerman}>{t("outbaseMessage:germany")}</button>

                <select className="col-md-2 offset-md-1 mt-1" onChange={setOutbase} onLoad={setOutbase}>
                    <option>{t("outbaseMessage:pleaseSelect")}</option>
                    {outbases.map((outbase, i) =>
                        <option key={i}>{outbase}</option>
                    )}
                </select>
                <button className="col-md-2 offset-md-1 mt-1 btn btn-success" onClick={getOutbaseLoads}>{t("outbaseMessage:select")}</button>
            </div>
            <div className="mt-4">
                {loadMessage.map((loadAndMessage, i) =>
                    <div key={i} className="row mb-1">
                        <span className="col-md-2 offset-md-1 col-sm-12">{loadAndMessage.loadNumber}</span>
                        <span className="col-md-7">{loadAndMessage.message}</span>
                        <hr className="col-md-10 offset-md-1 mt-4"></hr>
                    </div>
                )}
            </div>
        </div>
    )
}

export default GetOutbaseMessage;

