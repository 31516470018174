import { useTranslation } from 'react-i18next';
import './deletestandardmessages.css';
import { deleteStandardMessages } from '../../services/standardMessagesService';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from '../../store';
import { setLoadResponse, setShowAdditionalComponent } from '../../store/standardMessages/actions';
import { setErrorMessage, setSuccessMessage } from '../../store/global/actions';

export function DeleteStandardMessages() {

    const showAdditionalComponent = useSelector(
        (state: GlobalState) => state.standardMessages.showAdditionalComponent
    );
    const loads = useSelector(
        (state: GlobalState) => state.standardMessages.loads
    );
    const errorMessage = useSelector(
        (state: GlobalState) => state.global.errorMessage
    );

    const userName = useSelector(
        (state: GlobalState) => state.global.userName
    );

    const { t } = useTranslation();
    const dispatch = useDispatch();

    async function deleteMessage() {
        const loadsNoWhiteSpace = loads.replaceAll(" ", "");
        var loadArray = loadsNoWhiteSpace.split(",");

        setTimeout(() => {
            if (!response) {
                dispatch(setShowAdditionalComponent(false));
                dispatch(setErrorMessage(t('standardLoadMessage:cannotConnectToService')));
            }
        }, 10000);

        var response = await deleteStandardMessages(loadArray, userName);

        if (response.status === 200) {
            dispatch(setSuccessMessage(t("standardLoadsMessage:successfullyDeleted")));
            setTimeout(() => {
                dispatch(setShowAdditionalComponent(false));
                dispatch(setSuccessMessage(""));
                dispatch(setLoadResponse([]));
            }, 5000)
        } else {
            dispatch(setShowAdditionalComponent(false));
            dispatch(setErrorMessage(t('standardLoadMessage:cannotConnectToService')));
        }
    }

    return (
        <div id="deleteStandardMessagesContainer">
            {showAdditionalComponent && errorMessage === "" &&
                <div className="row">
                    <span className="col-md-9 offset-md-1"><b>{t('standardLoadsMessage:removeMessage')}</b></span>
                    <button className="btn btn-primary col-md-1" onClick={deleteMessage}>{t('standardLoadsMessage:yes')}</button>
                </div>}
        </div>
    )
}

export default DeleteStandardMessages