import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { GetOutbase } from '../../pages/outbases/getOutbase';
import { UpdateOutbase } from '../../pages/outbases/updateOutbase';
import { DeleteOutbase } from '../../pages/outbases/deleteOutbase';
import { GetEmergency } from '../../pages/emergencyMessage/getEmergency';
import { UpdateEmergency } from '../../pages/emergencyMessage/updateEmergency';
import { DeleteEmergency } from '../../pages/emergencyMessage/deleteEmergency';
import { GetStandard } from '../../pages/standardMessageLoad/getStandard';
import { UpdateStandard } from '../../pages/standardMessageLoad/updateStandard';
import { DeleteStandard } from '../../pages/standardMessageLoad/deleteStandard';
import { GetStandards } from '../../pages/standardMessageLoads/getStandards';
import { UpdateStandards } from '../../pages/standardMessageLoads/updateStandards';
import { DeleteStandards } from '../../pages/standardMessageLoads/deleteStandards';
import './navMenu.css';
import Collapsible from 'react-collapsible';

export function NavMenu() {

  const { t } = useTranslation();

  return (
    <Router>
      <div className='row'>
        <div id="sideNav" data-testid="sideNav" className='col-md-2 ps-4 mt-3'>
          <b>
            <Collapsible trigger={t("app:menu")} className="menuItem">
              <hr></hr>
              <div className="ms-1 menuItem p-2">
                <Collapsible trigger={t('app:emergencyMessage')}>
                  <div className="ms-2 mt-2">
                    <Link className="menuItem" to='/GetEmergencyMessage'>{t('app:get')}</Link>
                    <br />
                    <Link className="menuItem" to='/UpdateEmergencyMessage'>{t('app:addUpdate')}</Link>
                    <br />
                    <Link className="menuItem" to='/DeleteEmergencyMessage'>{t('app:delete')}</Link>
                  </div>
                </Collapsible>
              </div>
              <hr></hr>
              <div className="ms-1 menuItem p-2">
                <Collapsible trigger={t('app:standardLoadMessage')}>
                  <div className="ms-4 mt-2">
                    <Link className="menuItem" to='/GetStandardMessage'>{t('app:get')}</Link>
                    <br />
                    <Link className="menuItem" to='/UpdateStandardMessage'>{t('app:addUpdate')}</Link>
                    <br />
                    <Link className="menuItem" to='/DeleteStandardMessage'>{t('app:delete')}</Link>
                  </div>
                </Collapsible>
              </div>
              <hr></hr>
              <div className="ms-1 menuItem p-2">
                <Collapsible trigger={t('app:standardLoadsMessage')}>
                  <div className="ms-4 mt-2">
                    <Link className="menuItem" to='/GetStandardMessages'>{t('app:get')}</Link>
                    <br />
                    <Link className="menuItem" to='/UpdateStandardMessages'>{t('app:addUpdate')}</Link>
                    <br />
                    <Link className="menuItem" to='/DeleteStandardMessages'>{t('app:delete')}</Link>
                  </div>
                </Collapsible>
              </div>
              <hr></hr>
              <div className="ms-1 menuItem p-2">
                <Collapsible trigger={t('outbaseMessage:outbaseMessage')}>
                  <div className="ms-4 mt-2">
                    <Link className="menuItem" to='/GetOutbase'>{t('app:get')}</Link>
                    <br />
                    <Link className="menuItem" to='/UpdateOutbase'>{t('app:addUpdate')}</Link>
                    <br />
                    <Link className="menuItem" to='/DeleteOutbase'>{t('app:delete')}</Link>
                  </div>
                </Collapsible>
              </div>
            </Collapsible>
          </b>
          <hr></hr>
        </div>
        <div className='col-md-10'>
          <Switch>
            <Route exact path='/GetEmergencyMessage'>
              <GetEmergency />
            </Route>
            <Route exact path='/UpdateEmergencyMessage'>
              <UpdateEmergency />
            </Route>
            <Route exact path='/DeleteEmergencyMessage'>
              <DeleteEmergency />
            </Route>
            <Route exact path='/GetStandardMessage'>
              <GetStandard />
            </Route>
            <Route exact path='/UpdateStandardMessage'>
              <UpdateStandard />
            </Route>
            <Route exact path='/DeleteStandardMessage'>
              <DeleteStandard />
            </Route>
            <Route exact path='/GetStandardMessages'>
              <GetStandards />
            </Route>
            <Route exact path='/DeleteStandardMessages'>
              <DeleteStandards />
            </Route>
            <Route exact path='/UpdateStandardMessages'>
              <UpdateStandards />
            </Route>
            <Route exact path='/GetOutbase'>
              <GetOutbase />
            </Route>
            <Route exact path='/UpdateOutbase'>
              <UpdateOutbase />
            </Route>
            <Route exact path='/DeleteOutbase'>
              <DeleteOutbase />
            </Route>
          </Switch>
        </div>
      </div>
    </Router >
  )
}

export default NavMenu
