export interface StandardMessageState {
    loadNumber: string,
    message: string,
    showAdditionalComponent: boolean,
}

export const SET_LOAD_NUMBER = "SET_LOAD_NUMBER";
export const SET_MESSAGE = "SET_MESSAGE";
export const SET_SHOW_ADDITIONAL_COMPONENT = "SET_SHOW_ADDITIONAL_COMPONENT";

export interface SetLoadNumber {
    type: typeof SET_LOAD_NUMBER;
    payload: StandardMessageState['loadNumber'];
}

export interface SetMessage {
    type: typeof SET_MESSAGE;
    payload: StandardMessageState['message'];
}

export interface SetShowAdditionalComponent {
    type: typeof SET_SHOW_ADDITIONAL_COMPONENT;
    payload: StandardMessageState['showAdditionalComponent'];
}

export type StandardMessageActionType =
    SetLoadNumber
    | SetMessage
    | SetShowAdditionalComponent;